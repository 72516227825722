import React from 'react';
import { Link } from 'gatsby';
import GRAPHIC from '../../../images/specials/hero/Oct24-Badge.svg';

function SpecialsHeroContent({ setShowSignUpModal }) {
  return (
    <div id='social-hero-content' className='container'>
      <div className='row'>
        <div className='offset-xs-1 col-md-6 offset-md-6'>
          <figure className='d-none d-md-block pb-4 text-center'>
            <img
              id='graphic'
              src={GRAPHIC}
              alt='Milan Laser Social'
              style={{ width: '78.65%' }}
            />
          </figure>

          <p className='pt-3 pt-md-none'>
            Hey, boo! It’s time to ghost your razor, trick unwanted hair, and
            treat yourself to one FREE treatment* plus{' '}
            <Link to='/specials' className='milan-text-primary'>
              50% OFF unlimited laser hair removal
            </Link>{' '}
            and 1 select body area FREE — no bones about it. Our{' '}
            <Link to='#lp-unlimited-package' className='milan-text-primary'>
              exclusive Unlimited Package™
            </Link>{' '}
            comes with every purchase and includes FREE touch-ups, so you’re
            covered for life at any of our 380+ locations nationwide. Grab your
            skeleton crew and visit your{' '}
            <Link to='/locations' className='milan-text-primary'>
              local Milan Laser
            </Link>{' '}
            by <strong>October 31</strong> to get half-off unlimited laser hair
            removal and 1 FREE body area!
          </p>
          <div className='mt-4 text-center'>
            <Link className='milan-btn milan-cta-btn w-auto' to='#consult'>
              Book My Free Consult
            </Link>
          </div>
          <div className='mt-4'>
            <p className='mb-0 milan-fp pb-0'>
              <span
                className='text-decoration-underline pointer'
                onClick={() => setShowSignUpModal(true)}
              >
                Stay up to date with special discounts and sales by subscribing
                to our email list.
              </span>
              We promise we'll never spam you or sell your information.
            </p>
            <p className='pb-milan-50 mt-2 milan-fp'>
              *Call or visit clinic for details.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecialsHeroContent;

import React from 'react';
import { Carousel } from 'react-bootstrap';
import './SpecialsHero.css';
import SpecialsHeroContent from './SpecialsHeroContent';

export const HOME_PAGE_COPY = {
  default: 'Ghost your razor! | Get 50% OFF + 1 FREE BODY AREA.',
  grandOpening:
    'Grand Opening Sale: | 50% OFF unlimited laser hair removal + 1 FREE BODY AREA.',
};

export function SpecialsHero({ siteData, setShowSignUpModal }) {
  return (
    <section id='specials-hero' className='hero position-relative py-0'>
      <div className='d-lg-none stacked-hero-bg' />
      <div className='d-lg-none'>
        <SpecialsHeroContent
          className='d-lg-none'
          siteData={siteData}
          setShowSignUpModal={setShowSignUpModal}
        />
      </div>
      <div className='d-none d-lg-block'>
        <Carousel
          wrap
          fade
          interval={6500}
          touch={false}
          controls={false}
          indicators={false}
          keyboard={false}
        >
          <Carousel.Item className='first-bg'>
            <SpecialsHeroContent
              siteData={siteData}
              setShowSignUpModal={setShowSignUpModal}
            />
          </Carousel.Item>
          <Carousel.Item className='second-bg'>
            <SpecialsHeroContent
              siteData={siteData}
              setShowSignUpModal={setShowSignUpModal}
            />
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
}
export default SpecialsHero;
